<template>
  <v-container
    class="container fill-height grid-list-xl"
    fluid
    align-start
    justify-start
  >
    <v-row>
      <v-col
        cols="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-container
          grid-list-xs
          class="mt-0 mb-0 pt-0 pb-0"
        >
          <v-list-item two-line>
            <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
              <v-list-item-title><h2>{{ user.username }}</h2></v-list-item-title>
              <v-list-item-subtitle> Bienvenid@ </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        class="mt-0 pt-0"
      >
        <v-container grid-list-xs>
          <v-layout
            row
            wrap
          >
            <v-flex
              md12
              sm12
              lg8
              align="stretch"
            >
              <v-card height="100%">
                <v-card-title primary-title>
                  <b class="title">Ultimos Clientes</b>
                </v-card-title>
                <v-divider />
                <v-simple-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Nombre
                        </th>
                        <th class="text-left">
                          Rif
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(disp, item) in desserts"
                        :key="item"
                        @click="verMedidor(disp.name)"
                      >
                        <td>{{ disp.nombre }}</td>
                        <td>{{ disp.nrorif }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-actions>
                  <v-btn
                    text
                    color="deep-purple accent-4"
                    @click="ir('/clientes')"
                  >
                    Ir a Clientes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
            <v-flex
              md12
              sm12
              lg4
            >
              <v-card height="100%">
                <v-card-title primary-title>
                  <b class="title"> Usuario </b>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Nombre de Usuario</v-list-item-title>
                      <v-list-item-subtitle> {{ user.username }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Ultimo acceso</v-list-item-title>
                      <v-list-item-subtitle> {{ user.last_login }} </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        class="mt-0 pt-0"
      >
        <!--  <Maps  class="mapa" ref="mapaContr"  :point="points"  ></Maps> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

// import Maps from '../../maps/Mapa.vue'

import { mapGetters } from 'vuex'

export default {
	components: {
		// Maps
	},
	data: () => ({
		dialog: false,
		points: [],
		headers: [
			{ text: 'Company', align: 'left', value: 'comp' },
			{ text: 'Nombre', value: 'name' },
			{ text: 'Actions', value: 'action', sortable: false }
		],
		desserts: []
	}),
	computed: {
		...mapGetters({
			user: 'user'
		})
	},
	created: function () {
		// this.getPointDisp()

		this.getLastView()
	},
	methods: {
		getPointDisp () {
			this.$http.get('devices/map/')
				.then(resp => {
					this.points = resp.data
					// console.log(this.points)
					this.$refs.mapaContr.activeMarker()
				})
				.catch(err => {
					console.log(err)
				})
		},
		getLastView () {
			this.$http.post('cliente/clientesAll/', {
				user: this.user.id
			})
				.then(resp => {
					this.desserts = resp.data.slice(0, 2)
				})
				.catch(err => {
					console.log(err)
				})
		},
		verMedidor (name) {
			// console.log(name)
			this.$router.push({ name: 'device', params: { name: name } })
		},
		ir (url) {
			this.$router.push(url)
		}
	}
}
</script>

<style lang="scss" scoped>
  .mapa {
    height: 425px;
  }
</style>
